<template>
  <div>
    <img
      src="@/assets/images/header.jpg"
      alt="Bundle header picture"
      class="apply-header-picture"
      width="800"
      height="188"
      loading="lazy"
    />
  </div>
</template>

<script>
export default {
  name: 'ApplyHeaderPicture',
};
</script>
<style lang="scss" scoped></style>
