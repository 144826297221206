<template>
  <div class="apply-benefits-list">
    <Margins>
      <h6 class="text-normal">
        <span class="header-line">{{ $t('FAQ_TITLE') }}</span>
      </h6>

      <p class="text-small text-grey margins__double">
        <router-link
          :to="ROUTES.FAQ.path"
          :title="$t('FAQ_LINK_TEXT')"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t('FAQ_LINK_TEXT') }}
        </router-link>
      </p>
    </Margins>
  </div>
</template>

<script>
import { Margins } from '@/components';
import { constants } from '@/mixins';
import { getDynamicTranslation } from '@/utils';

export default {
  name: 'ApplyBenefitsList',
  mixins: [constants],
  components: {
    Margins,
  },
  computed: {
    texts() {
      return getDynamicTranslation(this.$i18n, 'FAQ_TEXTS');
    },
  },
};
</script>
