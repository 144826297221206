<template>
  <table class="table table-borderless">
    <tbody>
      <tr>
        <td colspan="2" class="no-padding">
          <table class="table table-borderless">
            <tr v-for="service in services" :key="service.id">
              <td>
                <ServiceSVG
                  v-if="service.type === SERVICE_TYPES.SERVICE"
                  class="icon table__icon mr-3"
                />
                <GuaranteeSVG
                  v-if="service.type === SERVICE_TYPES.GUARANTEE"
                  class="icon table__icon mr-3"
                />
                <DiscountSVG
                  v-if="service.type === SERVICE_TYPES.DISCOUNT"
                  class="icon table__icon mr-3"
                />
                <div class="table__icon-text">
                  <p>{{ service.name }}</p>
                </div>
              </td>
              <td class="text-right text-nowrap vertical-top">
                {{ getServicePeriod(service.period) }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr v-if="isPhysicalStore">
        <td>{{ $t('BUNDLE_PRODUCT_CODE') }}</td>
        <td class="text-right">
          {{ selectedBundle.productCode }}
        </td>
      </tr>
      <tr>
        <td>{{ $t('YOUR_PLAN_TABLE.CATEGORY_2') }}</td>
        <td class="text-right">{{ selectedBundle.totalValue }} kr</td>
      </tr>
      <tr v-if="showPeriod">
        <td>{{ $t('YOUR_PLAN_TABLE.CATEGORY_5') }}</td>
        <td class="text-right">
          {{ selectedBundle.upgradePeriod }} {{ $t('MONTHS') }}
        </td>
      </tr>
      <tr v-if="showPeriod">
        <td>{{ $t('YOUR_PLAN_TABLE.CATEGORY_6') }}</td>
        <td class="text-right">
          {{ selectedBundle.totalPeriod }} {{ $t('MONTHS') }}
        </td>
      </tr>
      <tr>
        <td class="text-strong">{{ $t('YOUR_PLAN_TABLE.CATEGORY_TOTAL') }}</td>
        <td class="text-right text-normal text-strong h6">
          {{ selectedBundle.monthlyPayment }} kr/{{ $t('MONTH_ABBREVIATION') }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapState } from 'vuex';
import ServiceSVG from '@/assets/images/icon-gear.svg';
import DiscountSVG from '@/assets/images/icon-percent.svg';
import GuaranteeSVG from '@/assets/images/icon-shield.svg';
import { constants, getServicePeriod } from '@/mixins';

export default {
  name: 'YourPlanTable',
  mixins: [constants, getServicePeriod],
  components: {
    ServiceSVG,
    DiscountSVG,
    GuaranteeSVG,
  },
  props: {
    showPeriod: Boolean,
  },
  computed: {
    ...mapState(['selectedBundle', 'services', 'isPhysicalStore']),
  },
};
</script>

<style lang="scss">
.table__icon {
  display: inline-flex;
  color: $color-primary;
  font-size: rem(26px);
  height: 26px;
}

.table__icon-text {
  display: inline-flex;
}

.table.table-borderless tr + tr {
  border: 0;
}
</style>
