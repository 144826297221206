<template>
  <div class="apply-contact-form">
    <Margins>
      <h5 class="text-normal">
        {{ $t('ASK_QUESTION') }}
      </h5>

      <Alert v-if="isSubmitted" class="margins__double">
        <Margins>
          <h5>{{ $t('MESSAGE_SENT') }}</h5>
          <p>{{ $t('MESSAGE_SENT_1') }}</p>
        </Margins>
      </Alert>

      <form
        v-else
        @submit="handleSubmit"
        class="margins__double"
        autocomplete="off"
      >
        <InputField
          name="bot-field"
          label="Don’t fill this out if you're human"
          v-model="formData.honeypot"
          autocomplete="random"
          isHoneyPot
        />

        <Margins>
          <InputField
            size="small"
            labelHidden
            :label="$t('FORM.NAME')"
            :placeholder="$t('FORM.NAME')"
            name="name"
            autocomplete="name"
            v-model.trim="formData.name"
          />

          <InputField
            type="email"
            size="small"
            labelHidden
            :label="`${$t('FORM.EMAIL')} *`"
            :placeholder="`${$t('FORM.EMAIL')} *`"
            name="email"
            autocomplete="email"
            v-model.trim="formData.email"
            :error="errors.first('email')"
            v-validate="{ required: true, email: true }"
          />

          <InputField
            type="textarea"
            size="small"
            labelHidden
            :label="`${$t('FORM.MESSAGE')} *`"
            :placeholder="`${$t('FORM.MESSAGE')} *`"
            name="message"
            :maxlength="maxMessage"
            :count="messageCharactersRemaining"
            v-model.trim="formData.message"
            :error="errors.first('message')"
            class="apply-contact-form__textarea"
            v-validate="{ required: true, max: maxMessage }"
          />

          <div class="text-center margins__double">
            <Button type="submit" :loading="isLoading">{{ $t('SEND') }}</Button>
          </div>
        </Margins>
      </form>
    </Margins>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ASK_QUESTION } from '@/types';
import { Margins, InputField, Button, Alert } from '@/components';

export default {
  name: 'ApplyContactForm',
  components: {
    Margins,
    InputField,
    Button,
    Alert,
  },
  data() {
    return {
      formData: {},
      maxMessage: 500,
      isSubmitted: false,
    };
  },
  methods: {
    ...mapActions([ASK_QUESTION]),
    async handleSubmit(e) {
      e.preventDefault();

      if (this.formData.honeypot) {
        return false;
      }

      const valid = await this.$validator.validateAll();
      if (!valid) {
        return false;
      }

      try {
        await this.ASK_QUESTION(this.formData);

        this.isSubmitted = true;
        setTimeout(() => {
          this.isSubmitted = false;
        }, 6000);

        this.formData = {};
        this.$validator.reset();
      } catch (error) {}
    },
  },
  computed: {
    ...mapState(['isLoading']),
    messageCharactersRemaining() {
      return this.maxMessage - (this.formData.message || '').length;
    },
  },
};
</script>
