<template>
  <div class="remove-bundle-card">
    <Margins>
      <h6
        class="remove-bundle-card__title text-normal"
        v-if="(isFull && assets.length > 1) || !isFull"
      >
        {{ $tc('YOUR_PRODUCTS', assets.length) }}
      </h6>

      <Card
        color="grey"
        padding="small"
        v-for="asset in assets"
        :key="asset.id"
        class="remove-bundle-card__card"
      >
        <b-row align-v="center" align-h="center">
          <b-col cols="7" sm="6" :md="isFull ? 2 : 3">
            <img
              :data-src="`${API_BASE_URL}/asset-picture/${asset.id}/product`"
              class="lazyload"
              :alt="asset.name"
            />
          </b-col>
          <b-col :md="isFull ? 10 : 9">
            <div class="remove-bundle-card__content">
              <Margins>
                <h6>{{ asset.name }}</h6>
                <p class="remove-bundle-card__additional-info">
                  {{ asset.additionalInfo }}
                </p>
                <div class="h6 text-normal">
                  <List
                    :items="mapSecondaryAssets(asset.secondaryAssets)"
                    variant="icons"
                    icon="plus"
                    size="small"
                  />
                </div>
              </Margins>
            </div>
          </b-col>
        </b-row>
      </Card>
    </Margins>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { constants } from '@/mixins';
import { Card, Margins, List } from '@/components';

export default {
  name: 'ApplyAssetCard',
  mixins: [constants],
  components: {
    Card,
    Margins,
    List,
  },
  props: {
    isFull: Boolean,
  },
  methods: {
    mapSecondaryAssets(secondaryAssets) {
      return secondaryAssets.map((asset) => asset.name);
    },
  },
  computed: {
    ...mapState(['selectedBundle']),
    assets() {
      return this.selectedBundle?.assets || [];
    },
  },
};
</script>

<style lang="scss">
.remove-bundle-card__card {
  + .remove-bundle-card__card {
    margin-top: 0.5rem;
  }
}

.remove-bundle-card__title {
  @include min-width(sm) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.remove-bundle-card__content {
  font-size: $font-size-small;
  padding: 1rem 0;

  @include min-width(md) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.remove-bundle-card__additional-info {
  color: $color-grey-dark;
  font-size: $font-size-extra-small;
}

.remove-bundle-card__price {
  text-align: center;

  @include min-width(md) {
    text-align: right;
  }
}
</style>
