<template>
  <b-row align-v="center">
    <b-col cols="12" sm="12" md="12">
      <List
        :items="bulletsList"
        variant="numbers"
        size="small"
        class="text-small"
        noMargin
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex';
import { List } from '@/components';
import { constants } from '@/mixins';

export default {
  name: 'YourPlanList',
  mixins: [constants],
  components: {
    List,
  },
  computed: {
    ...mapState(['selectedBundle']),
    bulletsList() {
      if (!this.selectedBundle) {
        return [];
      }

      const { totalValue, totalPeriod, upgradePeriod } = this.selectedBundle;

      return [
        this.$t('YOUR_PLAN_BULLETS_LIST.ITEM_1', [totalValue]),
        this.$t('YOUR_PLAN_BULLETS_LIST.ITEM_2', [totalPeriod]),
        this.$t('YOUR_PLAN_BULLETS_LIST.ITEM_3', [upgradePeriod]),
        this.$t('YOUR_PLAN_BULLETS_LIST.ITEM_4', [totalPeriod]),
      ];
    },
  },
};
</script>
